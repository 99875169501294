import { useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";

type LogLevel = "info" | "warn" | "error" | "debug";
type LoggerContext = Record<string, unknown>;

const sentryLevels: Record<LogLevel, Sentry.SeverityLevel> = {
  info: "info",
  warn: "warning",
  error: "error",
  debug: "debug",
};

export function initializeSentry() {
  Sentry.init({
    dsn: "https://6db70e3cec59f6298680eca74b564300@o4507195288518656.ingest.us.sentry.io/4507195290288128",
    tracesSampleRate: 1,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect: useEffect,
        useLocation: useLocation,
        useMatches: useMatches,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  });
}

export function initializeBrowserLogger(componentName?: string) {
  const baseContext = { componentName };

  const log =
    (level: LogLevel) =>
    (message: string, context: LoggerContext = {}) => {
      const logObject = { ...baseContext, ...context };

      // Add breadcrumb for all log levels
      Sentry.addBreadcrumb({
        category: "log",
        message,
        data: logObject,
        level: sentryLevels[level],
      });

      // Log to console in development
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1"
      ) {
        console[level](message, logObject);
      }

      // Capture exception for errors
      if (level === "error") {
        Sentry.captureException(new Error(message), { extra: logObject });
      }
    };

  return {
    info: log("info"),
    warn: log("warn"),
    error: log("error"),
    debug: log("debug"),
  };
}
